(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/win-message/assets/javascripts/win-message.js') >= 0) return;  svs.modules.push('/components/components/win-message/assets/javascripts/win-message.js');

var svs = svs || {};

(function(svs, $) {
  'use strict';

  svs.components = svs.components || {};
  svs.components.WinMessage = function() {
    $('body').on('click', '.js-win-message-close', function() {
      $('.js-win-message-more-info').addClass('hide');
    });

    $('body').on('click', '.js-win-message-open', function() {
      $('.js-win-message-more-info').removeClass('hide');
    });
  };

  svs.components.winMessage = new svs.components.WinMessage();
})(svs, jQuery);


 })(window);